import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import { getValidationErrors } from "../../utils/graphql-error-handler"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import { isBrowser } from "../../utils/browser"
import { MUTATION_RESET_PASSWORD } from "../../graphql/mutations/auth"
import { useAlert } from "react-alert"
import { useAuth } from "../../context/auth-context"

function ResetPasswordForm() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get("token")
  const [errorMessages, setErrorMessages] = useState([])
  const [isProcessing, setIsProcessing] = useState(false)
  const [resetPassword] = useMutation(MUTATION_RESET_PASSWORD)
  const alert = useAlert()
  const { logout } = useAuth()

  const [resetPasswordData, setResetPasswordData] = useState({
    token,
    email: "",
    password: "",
    password_confirmation: "",
  })

  if (isBrowser() && token === null) {
    navigate("/")
    return null
  }

  const handleChange = ({ target }) => {
    setResetPasswordData({
      ...resetPasswordData,
      [target.name]: target.value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if(isProcessing) {
      return null
    }

    setIsProcessing(true)

    resetPassword({ variables: resetPasswordData })
      .then(({ data }) => {
        if (data.resetForgotPassword.status === "PASSWORD_RESET") {
          alert.success(data.resetForgotPassword.message)
          logout().then(() => {
            navigate("/login")
            setIsProcessing(false)
          })
        } else {
          throw new Error(data.resetForgotPassword.message)
        }
      })
      .catch((error) => {
        const messages = getValidationErrors(error)
        setIsProcessing(false)
        if (messages.length > 0) {
          setErrorMessages(messages)
        } else if (error.message) {
          setErrorMessages([error.message])
        } else {
          setErrorMessages([
            "Unable to reset your account password. Please try again!",
          ])
        }
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="w-full">
        <label className="block md:w-1/2 pr-3 mt-4 mb-2" htmlFor="subject">
          <span className="font-bold">Your Email Address</span>
          <p />
        </label>
        <input
          onChange={handleChange}
          className="appearance-none border border-grey rounded w-full py-3 px-4 leading-tight text-lg focus:outline-none focus:bg-white focus:border-primary"
          id="email"
          name="email"
          type="email"
          placeholder="something@website.com"
        />
        {errorMessages.length > 0 &&
          errorMessages.map((error) => (
            <div key={error}>
              <small className="text-error">{error}</small>
            </div>
          ))}
      </div>
      <div className="w-full">
        <label className="block md:w-1/2 pr-3 mt-4 mb-2" htmlFor="subject">
          <span className="font-bold">Password</span>
          <p />
        </label>
        <input
          onChange={handleChange}
          className="appearance-none border border-grey rounded w-full py-3 px-4 leading-tight text-lg focus:outline-none focus:bg-white focus:border-primary"
          id="password"
          name="password"
          type="password"
          placeholder="Password"
        />
      </div>
      <div className="w-full">
        <label className="block md:w-1/2 pr-3 mt-4 mb-2" htmlFor="subject">
          <span className="font-bold">Confirm Password</span>
          <p />
        </label>
        <input
          onChange={handleChange}
          className="appearance-none border border-grey rounded w-full py-3 px-4 leading-tight text-lg focus:outline-none focus:bg-white focus:border-primary"
          id="password_confirmation"
          name="password_confirmation"
          type="password"
          placeholder="Confirm Password"
        />
      </div>
      <div className="py-4">
        <input
          className="btn in-app -primary"
          type="submit"
          value={!isProcessing ? "Submit" : "Reseting "}
        />
      </div>
    </form>
  )
}

export default ResetPasswordForm
