import React from "react"
import Layout from "../components/layout"
import ResetPasswordForm from "../components/auth/reset-password-form"

function ResetPasswordPage() {
  return (
    <Layout>
      <div className="bg-grey-lighter">
        <div className="max-w-body mx-auto pt-8 px-4 pb-8 xl:px-0">
          <div className="w-full pb-8 flex items-center border-b border-grey">
            <div className="w-full">
              <h1 className="sub-headline-text">Forgot Password</h1>
              <p className="text-center" />
            </div>
          </div>
          <div className="w-10/12 md:w-1/2 mx-auto">
            <ResetPasswordForm />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ResetPasswordPage
